import Modal from "@/components/modal/Modal"
import { PDFViewer } from "@/features/pdf/PDFReader"
import { IFile } from "@/features/storage/types"
import { getItem } from "@/utils/browserStorage"

import { Popover } from "antd"
import Image from "next/image"
import { useCallback, useEffect, useMemo, useState } from "react"
import { BasicButton } from "./buttons/BasicButton"

type IFileUri = {
  uri: string
  mimetype: string
  part?: {
    name?: string
    uid?: string
    created?: string
  }
}

type IFilePreviewModal = {
  open: boolean
  setOpen: (open: boolean) => void

  header?: { title: string; handlers?: JSX.Element }
} & IFilePreview

type IFilePreview = {
  loading?: boolean
  default_selected?: IFile | IFileUri
} & (
  | {
      uris: IFileUri[] | undefined
    }
  | {
      files: IFile[] | undefined
    }
)

export const FilePreviewModal = ({
  open,
  setOpen,
  loading = false,
  header,
  default_selected,
  ...props
}: IFilePreviewModal) => {
  return (
    <Modal open={open} close={() => setOpen(!open)}>
      <div className="min-w-[60vw]">
        {header && (
          <header className="mb-1 flex items-center justify-between border-b pb-1">
            <p className="mb-2 text-2xl">{header.title || "Preview"}</p>
            <div className="flex gap-2">
              {header.handlers}
              <BasicButton className="" onClick={() => setOpen(!open)}>
                Close
              </BasicButton>
            </div>
          </header>
        )}
        <FilePreview
          loading={loading}
          default_selected={default_selected}
          {...props}
        />
      </div>
    </Modal>
  )
}

export const FilePreview = ({
  loading = false,
  default_selected,
  ...props
}: IFilePreview) => {
  const [selectedFile, setSelectedFile] = useState<
    IFile | IFileUri | undefined
  >()

  const setInitialState = useCallback(() => {
    if (default_selected) {
      setSelectedFile(default_selected)
    } else if ("files" in props && props.files) {
      setSelectedFile(props.files[0])
    } else if ("uris" in props && props.uris) {
      setSelectedFile(props.uris[0])
    } else {
      setSelectedFile(undefined)
    }
  }, [loading])

  useEffect(() => {
    default_selected && setSelectedFile(default_selected)
  }, [default_selected])

  useEffect(() => {
    setInitialState()
  }, [setInitialState])

  const getPartDataUrl = (part: string) => {
    return (
      process.env.NEXT_PUBLIC_API_URL +
      `/ai/parts/${part}/data/buffer?authToken=${getItem("_ft_a_")}`
    )
  }

  const renderFile = (file: IFile) => {
    if (!file) {
      return null
    }

    return renderUri({
      uri: `${process.env.NEXT_PUBLIC_API_URL}/storage/files/${file.uid}/download`,
      mimetype: file.mimetype,
    } as IFileUri)
  }

  const renderUri = (file: IFileUri) => {
    if (!file) {
      return null
    }
    switch (file.mimetype) {
      case "application/pdf":
      case "pdf":
        return <PDFViewer fileUrl={file.uri} />
      case "text/plain":
      case "text/html":
      case "txt":
      case "html":
        return (
          <iframe
            allow="scripts"
            src={file.uri}
            style={{ height: "500px", width: "100%" }}
          ></iframe>
        )
      case "image/jpeg":
      case "image/png":
      case "image/jpg":
      case "image/gif":
      case "image/webp":
      case "image/avif":
      case "jpeg":
      case "png":
      case "jpg":
      case "gif":
      case "webp":
      case "avif":
        return (
          <Image
            className="mx-auto"
            src={file.uri}
            width={1100}
            height={1100}
            objectFit="contain"
            alt="Preview image"
          />
        )
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "docx":
        let src = file?.part?.uid ? getPartDataUrl(file.part.uid) : file.uri

        return (
          <iframe
            allow="scripts"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${src}`}
            style={{ height: "500px", width: "100%" }}
          ></iframe>
        )

      default:
        return <div></div>
    }
  }

  const renderer = useMemo(() => {
    if ("files" in props && props.files) {
      return renderFile(selectedFile as IFile)
    } else if ("uris" in props && props.uris && props.uris.length > 0) {
      return renderUri(selectedFile as IFileUri)
    } else {
      return <div>File not supported</div>
    }
  }, [props, selectedFile])

  return (
    <>
      <div className="mb-2 flex flex-wrap gap-2">
        {"files" in props &&
          props.files &&
          props.files.length > 0 &&
          props.files.map((file) => (
            <div key={file?.uid} className="flex items-center gap-2">
              <Popover content={file?.filename}>
                <BasicButton
                  className="w-[250px] overflow-hidden truncate overflow-ellipsis text-sm"
                  onClick={() => setSelectedFile(file)}
                  variant={selectedFile === file ? "primary" : "default"}
                >
                  <span>{file?.filename}</span>
                </BasicButton>
              </Popover>
            </div>
          ))}
        {"uris" in props &&
          props.uris &&
          props.uris.length > 0 &&
          props.uris.map((file) => (
            <div key={file.uri} className="flex items-center gap-2">
              <Popover content={file?.part?.name}>
                <BasicButton
                  className="max-w-[250px] overflow-hidden truncate overflow-ellipsis text-sm"
                  onClick={() => setSelectedFile(file)}
                  variant={selectedFile === file ? "primary" : "default"}
                >
                  <span>{file?.part?.name ?? "CV "}</span>
                </BasicButton>
              </Popover>
            </div>
          ))}
      </div>
      <div className="relative">{renderer}</div>
    </>
  )
}
